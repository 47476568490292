import React from 'react';

import { cx } from 'utils';
import styles from './Block.module.css';

export type BlockProps = {
  background?: 'white' | 'off-white' | 'secondary';
  className?: string;
  component?: React.ElementType;
  disabled?: boolean;
  padding?: 'big' | 'regular' | 'small' | 'none';
} & React.HTMLProps<HTMLDivElement>;

/**
 * Block
 * Acts on the Y-axis considering padding,
 * the purpose of the block is to add tempo between sections and
 * to set the background color + control text color.
 */
const Block: React.FC<BlockProps> = ({
  background = 'white',
  className,
  component: Component = 'section',
  padding = 'regular',
  ...rest
}) => {
  return (
    <Component
      className={cx(
        styles.root,
        styles[`background-${background}`],
        styles[`padding-${padding}`],
        className,
      )}
      {...rest}
    />
  );
};

Block.displayName = 'Block';

export default Block;
