import React, { useEffect, useId, useMemo, useState } from 'react';
import { detect } from 'detect-browser';
import Link from 'next/link';

import api from 'api';
import { AvailabilityMap } from 'types/AvailabilityMap';
import { ProductSmall } from 'types/Product';
import { cx, SanityUrlBuilder } from 'utils';
import { HIGH_DEMAND, parseItemWarning } from 'utils/errors';
import formatPrice from 'utils/formatPrice';
import Label from '../Label';
import Typography from '../Typography';
import classes from './ProductItem.module.css';

export type ProductItemProps = React.ComponentProps<'article'> & {
  availabilityEntry?: AvailabilityMap[string];
  product: ProductSmall;
};

const ProductItem: React.FC<ProductItemProps> = ({
  availabilityEntry,
  className,
  product,
  ...restProps
}) => {
  const sold = availabilityEntry?.isAvailable === false;
  const id = useId().replace(/:(.+):/, 'product-item-$1-video');
  const [browser, setBrowser] = useState<string | null>(null);

  const builder = new SanityUrlBuilder(api().sanity.client);

  const label = useMemo(() => {
    const highDemand = !!availabilityEntry?.warnings
      .flatMap(warning => parseItemWarning(warning).codes)
      .includes(HIGH_DEMAND);

    if (highDemand && !sold) {
      return 'Många har denna i sin varukorg';
    }

    return void undefined;
  }, [availabilityEntry?.warnings, sold]);

  useEffect(() => {
    const element = document.getElementById(id) as HTMLVideoElement | null;

    if (element) {
      element.muted = true;
      element.defaultMuted = true;
    }
  }, [id]);

  useEffect(() => {
    setBrowser(detect()?.name ?? null);
  }, []);

  return (
    <Link href={`/produkt/${product.slug}?back=true`}>
      <a tabIndex={0}>
        <article className={cx(className, sold && classes.sold, classes.root)} {...restProps}>
          <div className={classes.inner}>
            <div className={classes.image}>
              <div className={classes.content}>
                <div
                  className={classes.image}
                  style={{
                    backgroundImage: `url("${builder
                      .image(
                        product.media.type === 'image'
                          ? product.media.source
                          : product.media.thumbnail,
                      )
                      .fit('fill')
                      .width(500 * 2)
                      .height(700 * 2)
                      .quality(80)
                      .focalPoint(0.5, 0.5)
                      .url()}")`,
                  }}
                ></div>
                {product.media.type === 'video' ? (
                  browser && ['safari', 'ios'].includes(browser) ? (
                    // Safari work around :^)
                    <img src={builder.asset(product.media.video).url()} className={classes.media} />
                  ) : (
                    <video
                      muted
                      autoPlay
                      loop
                      playsInline
                      src={builder.asset(product.media.video).url()}
                      className={classes.media}
                    />
                  )
                ) : null}
              </div>
              {label && <Label className={classes.label}>{label}</Label>}
            </div>
            <div className={classes.information}>
              <Typography className={classes.title} component="h3">
                {product.brand}
              </Typography>
              <Typography className={classes.name} component="p" variant="h3">
                {product.name}
              </Typography>
              <Typography className={classes.name} component="p">
                {sold && 'Såld - '}
                {`${formatPrice(product.price)}`} SEK
              </Typography>
            </div>
          </div>
        </article>
      </a>
    </Link>
  );
};

export default ProductItem;
