import { CACHE_TIMES } from 'consts';
import { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';

import api from 'api';
import Home from 'components/views/Home';
import { AppLayoutProps } from 'types/AppLayoutProps';
import { AvailabilityMap } from 'types/AvailabilityMap';
import { Collection } from 'types/Collection';
import { Look } from 'types/Look';
import { ProductSmall } from 'types/Product';
import { ScheduledRelease } from 'types/ScheduledRelease';
import { chunkAvailability, getBananasCommerce, getGroupReferences, setCacheHeaders } from 'utils';

export const getServerSideProps: GetServerSideProps<Props> = async ctx => {
  const sanity = api().sanity;

  // const hideSold = "hideSold" in ctx.query ?? false;
  const collection = ctx.query.collection as string | undefined;
  const page = (() => {
    const rawPage = parseInt(`${ctx.query.page}`);

    // To really make sure that this is a valid integer parseInt could return NaN
    if (!Number.isInteger(rawPage)) {
      return 1;
    }

    return Math.max(1, rawPage);
  })();

  // Fetch data for this request
  const [navbarMenu, footerMenu, collections, feed, sneakPeeks] = await Promise.all([
    sanity.navbarMenu(),
    sanity.footerMenu(),
    sanity.collections(),
    sanity.feed({
      collection,
      limit: page,
      offset: 0,
    }),
    sanity.nextScheduledReleases(),
  ]);

  const bcom = getBananasCommerce();
  const availabilityMap = Object.fromEntries(
    await chunkAvailability(bcom, getGroupReferences(feed)).then(v => {
      if (v._type !== 'success') {
        return [];
      }
      return v.groups.map(v => [v.reference, v] as const);
    }),
  );

  const sneakPeek = sneakPeeks.at(0) ?? null;
  const nextRelease = sneakPeek?.releaseAt ?? null;

  const props: Props = {
    availabilityMap,
    collections,
    feed,
    footerMenu,
    navbarMenu,
    nextRelease,
    page,
    showWelcomeBanner: true,
    sneakPeek,
  };

  setCacheHeaders(ctx.res, CACHE_TIMES.HOME);

  return {
    props,
  };
};

type Props = {
  availabilityMap: AvailabilityMap;
  collections: Collection[];
  feed: (ProductSmall | Look)[];
  page: number;
  sneakPeek: ScheduledRelease | null;
} & AppLayoutProps;

const Page: NextPage<Props> = props => {
  return (
    <>
      <Head>
        <title>Startsida - Arkivet Second Hand</title>
        <meta name="description" content="Arkivet Second Hand" />
        <meta property="og:locale" content="sv_SE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Startsida - Arkivet Second Hand" />
        <meta
          property="og:description"
          content="Med en hög modegrad och stort fokus på upplevelsen och känslan i butiken vill Arkivet göra det enkelt för kunderna att konsumera mode på ett hållbart sätt. Konceptet är enkelt; lämna in det som du inte längre använder men själv skulle kunna tänka dig att köpa, det vill säga moderna kläder i bra skick."
        />
        <meta property="og:url" content="https://arkivet.com/" />
      </Head>
      <Home
        page={props.page}
        collections={props.collections}
        availabilityMap={props.availabilityMap}
        sneakPeek={props.sneakPeek}
        feed={props.feed}
      />
    </>
  );
};

export default Page;
